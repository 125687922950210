.App {
  text-align: center;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  color: black;
  background: #fefefe;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #ccc;
}
table tr:nth-child(even) {
  background: #F5F5F5;
}
table tr:nth-child(odd) {
  background: #FFF;
}
table td {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
  white-space: nowrap;
  text-align: left;
}
table th {
  background: #27252C;
  padding: 5px;
  white-space: nowrap;
  color: #ccc;
  border: 1px solid #555;
  vertical-align: top;
}
table tr:hover td {
  background: #DDD;  
}
tr.inputs th {
  padding-right: 6px;
}
input[type="text"], input[type="password"] {
  width: 100%;
}
.clearButton {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}
select {
  width: 100%;
}
.checkbox {
  font-size: .85em;
  font-weight: 100;
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  margin-top: 5px;
}
.checkbox input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  overflow: hidden;
}
.info {
  color: #ccc;
  background: #27252C;
  text-align: left;
}
.info span {
  display: inline-block;
  padding: 8px 24px;
}
.loginForm_wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginForm {
  width: 300px;
  background: #27252C;
  margin: 0 auto;  
  padding: 24px 36px 16px 36px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.5);
  border-radius: 2px;
}
.loginForm form {
  margin-right: 10px;
}
.loginForm input {
  margin: 10px 0;
  padding: 8px 4px;
}
.loginForm input[type="submit"] {
  text-transform: uppercase;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
}
.logoutButton {
  padding-top: 2px;
  float: right;
}
.loading_wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}